<template>
  <div>
    <section
      style="
    height: 100px; padding-top: 20px; text-align: left;"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h1>{{ env }}Payment Request</h1>
          </div>
        </div>
      </div>
    </section>

    <div v-if="paymentLoaded">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <p style="text-align: left;">
              You have been requested to make a payment to your account. Please
              make the payment using the form below
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6" id="payment-element">
            <h2>Make Payment</h2>
            <div
              class="block tabular"
              style="padding: 20px; position: relative;"
            >
              <p v-if="!paymentComplete">
                Add credit to your account. Enter the amount below then continue
                to securely enter your card details
              </p>

              <div v-if="paymentComplete">
                <b-alert variant="success" show>
                  <strong>Payment Complete</strong>
                  <div>
                    We have received your payment and are updating your balance
                  </div>
                </b-alert>
              </div>

              <div v-if="stripeElementsSecret && !paymentComplete">
                <div
                  style="max-width: 600px; border: 1px solid #bbb; transition: box-shadow .2s ease,-webkit-box-shadow .2s ease; padding: 5px;"
                  id="card-element"
                ></div>

                <div v-if="isDebt" class="debt-options">
                  <div>
                    Please note:
                    <span>{{ payment.debt_percent }}&percnt;</span> of each
                    top-up will be taken for debt recovery
                  </div>
                </div>

                <div class="payment-button">
                  <b-button
                    v-on:click="purchase"
                    variant="outline-success"
                    v-if="!paymentLoading"
                    >Pay £{{ payment.amount }}</b-button
                  >
                  <div v-if="paymentLoading">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "payment",
  props: ["id"],
  mounted: function() {
    this.configureStripe();
  },
  data() {
    return {
      stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      stripe: "",
      elements: "",
      card: "",
      requestAmount: "",
      paymentLoading: false,
      stripeElementsSecret: "",
      payment: {},
      paymentComplete: false,
      paymentLoaded: false
    };
  },
  computed: {
    env() {
      return process.env.VUE_APP_ENV;
    },
    isDebt() {
      return this.payment.debt_percent && this.payment.debt_percent > 0;
    }
  },
  created() {
    this.$store.dispatch("loaderCancel");
    this.fetchData();
  },
  methods: {
    formatPrice(price) {
      if (price >= 0) {
        return price;
      }

      return Math.abs(price);
    },
    resetPayment() {
      this.requestAmount = "";
      this.paymentComplete = false;
    },
    requestPayment() {
      this.paymentLoading = true;

      let amount = this.requestAmount;
      let errorReason = null;

      if (isNaN(amount)) {
        errorReason = "Please enter a valid payment amount";
      }

      if (
        !errorReason &&
        Math.floor(amount) !== amount &&
        amount.toString().split(".")[1] !== undefined &&
        amount.toString().split(".")[1].length > 2
      ) {
        errorReason = "Please enter a valid payment amount";
      }

      if (errorReason) {
        const title = "Payment Error";
        const subtitle = errorReason;
        const samePage = true;
        this.$store.dispatch("pushError", { title, subtitle, samePage });
        this.paymentLoading = false;
        return;
      }

      this.requestAmount = amount = parseFloat(amount).toFixed(2);

      this.$store
        .dispatch("requestPayment", { amount })
        .then(response => {
          this.stripeElementsSecret = response.data.ref;

          this.$nextTick(() => {
            this.card.mount("#card-element");
          });
        })
        .catch(err => {
          const title = "Payment Error";
          const subtitle = err;
          const samePage = true;
          this.$store.dispatch("pushError", { title, subtitle, samePage });
        })
        .finally(() => {
          this.paymentLoading = false;
        });
    },
    configureStripe() {
      this.stripe = window.Stripe(this.stripeAPIToken);

      let style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };

      this.elements = this.stripe.elements();
      this.card = this.elements.create("card", { style: style });
    },
    purchase() {
      this.paymentLoading = true;
      this.stripe
        .confirmCardPayment(this.stripeElementsSecret, {
          payment_method: {
            card: this.card
          }
        })
        .then(result => {
          if (result.error) {
            let gTagCategory = "paymentErrorPrePayRequestCard";
            let eventLabel = result.error.message;
            let customerError = result.error.message;

            if (result.error.type !== "card_error") {
              gTagCategory = "paymentErrorPrePayRequestApp";
              customerError =
                "We were unable to process this payment. Please contact us if this issue persists.";
            }

            if (result.error.type === "validation_error") {
              customerError = result.error.message;
            }

            if (
              result.error.payment_method &&
              result.error.payment_method.card.brand === "amex"
            ) {
              eventLabel = "amex";
              customerError =
                "We no longer accept American Express Payments. Please use an alternative payment method";
            }

            this.$gtag.event(result.error.type, {
              event_category: gTagCategory,
              event_label: eventLabel
            });
            // Show error to your customer (e.g., insufficient funds)
            const title = "Payment Error";
            const subtitle = customerError;
            const samePage = true;
            this.$store.dispatch("pushError", { title, subtitle, samePage });
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === "succeeded") {
              this.$gtag.event("payment", {
                event_category: "paymentSuccessPrePayRequest"
              });
              this.card.clear();
              this.stripeElementsSecret = null;
              this.paymentComplete = true;
              const title = "Payment Processing";
              const subtitle =
                "Your payment will appear on your dashboard shortly";
              const samePage = true;
              this.$store.dispatch("pushSuccess", {
                title,
                subtitle,
                samePage
              });
            }
          }
          this.paymentLoading = false;
        });
    },
    clearCard() {
      this.card.clear();
    },
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function(e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    fetchData() {
      const title = "Fetching Payment";
      const subtitle = "Getting payment details";
      this.$store.dispatch("loaderInitiate", { title, subtitle });

      const reference = this.id;

      this.$store
        .dispatch("getPayment", { reference })
        .then(response => {
          this.payment = response.data;
          this.paymentLoaded = true;

          this.stripeElementsSecret = response.data.secret;

          this.$nextTick(() => {
            this.card.mount("#card-element");
          });
        })
        .catch(() => {
          this.$swal({
            title: "Invalid Request",
            text:
              "Invalid link for payment. If this problem persists please contact us",
            type: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK"
          }).then(result => {
            if (result.value) {
              this.$router.push("/login");
            }
          });
        })
        .finally(() => {
          this.$store.dispatch("loaderCancel");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 1.6rem !important;
  text-align: left !important;
  margin-bottom: 10px !important;
  padding-left: 10px;
  margin-top: 20px;
}

.block-icon {
  padding: 0px !important;
  line-height: 2.5rem;

  .head {
    border-bottom: 1px solid #eee;
    text-align: center;
    position: relative;

    .icon {
      font-size: 2.5rem;
      position: absolute;
      left: 15px;
      top: 0px;
      color: purple;
    }

    .heading {
      font-size: 0.8rem;
      font-weight: bold;
      text-transform: uppercase;
      color: purple;
    }
  }

  .content {
    color: #555;
    font-size: 2.5rem;
    text-align: center;
    padding-top: 15px;

    .credit {
      color: #008c00;
    }

    .negative {
      color: #ff7400;
    }

    .disconnect {
      color: #cc0000;
    }

    .sub {
      font-size: 1rem;
      color: #999;
    }
    .sub:after {
      content: ".";
      visibility: hidden;
    }
  }
}
.summary {
  height: 100px;
  .upper {
    font-size: 2rem;

    .lower {
      font-size: 1rem;
    }
  }
}
.chart {
  width: 95%;
  height: 500px;
}
.row {
  margin-top: 20px;
  margin-bottom: 20px;
}
.active-state {
  &.danger {
    color: $alertDanger;
  }

  &.warning {
    color: $alertWarning;
  }
}
.endpoint-summary-item {
  display: inline-block;
  margin: 15px;

  .header {
    margin: 5px;
    font-size: 1.1rem;
  }

  .count {
    margin: 5px;
    font-size: 1.4rem;
  }
}

.payment-button {
  text-align: right;
  padding-top: 15px;
}

.debt-options {
  text-align: left;
  margin-top: 10px;
  font-size: 0.9rem;

  span {
    font-weight: bold;
  }

  .button-option {
    margin-top: 10px;
  }
}
</style>
